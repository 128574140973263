import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Hva er bekkensmerter/ bekkenløsning?</h1>
      <p>
        Bekkensmerter er når en har smerter som typisk sitter på en eller begge sidene rett over halebeinet. Smertene
        kan være lokale og kan kjennes både verkende og stikkende. Ofte er dette også i kombinasjon med utstrålende
        smerter ned i et eller begge beina, frem i lysk/lyskene og/eller i korsryggen. Dersom utstrålingen er forbi
        kneleddet så er ofte skivene i korsryggen involvert i problemet. Desto lenger det stråler ned, desto større er
        ofte problemet.
      </p>

      <p>
        Bekkensmerter kan også komme fra bekkenløsning. Bekkenløsning er prosessen der leddbånd i bekkenet blir løsere
        under graviditeten på grunn av hormonet relaxin. Om man allerede har et latent problem i korsrygg, hofter, eller
        bekken vil dette kunne slå ut ved en bekkenløsning. Årsaken til smertene ved bekkenløsning er at ett eller begge
        bekkenleddene beveger seg for mye.
      </p>

      <p>
        Bekkensmerter er en av de vanligste årsakene til at folk oppsøker kiropraktor. Det vi si at dette er noe vi
        behandler daglig og har veldig god erfaring med. Hver behandling varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
